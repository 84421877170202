body {
  padding-top: 118px;
  // letter-spacing: -1px;
}
.container {
    padding-left: 0;
    padding-right: 0;
}

.paginate-links {
  margin: 10px 0;
  font-size: 18px;
}
a {
    color: #f19700;
    text-decoration: none;
}
.none {
    color: black;
    text-decoration: none !important;
}
.none:hover {
  color: black;
}
.merchan-fixed {
  position: fixed;
  top: 59px;
  right: 45px;
}
.noborder {
    border: 0 !important;
}

.nopadding {
  padding: 0;
}

.nopadding-right {
  padding-right: 0;
}

.nopadding-left {
  padding-left: 0;
}

.overflow-hidden {
    overflow: hidden;
}

.nomargin {
  margin: 0;
}

.titulo {
  font-weight: bold;
  display: block;
  letter-spacing: -1px;
}
.h2 {
  font-size: 30px;
  letter-spacing: -1px;
}
.h3 {
  font-size: 24px;
  letter-spacing: -1px;
}
.h4 {
  font-size: 20px;
  letter-spacing: -1px;
}
.h5 {
  font-size: 18px;
  letter-spacing: -1px;
}
.bottom {
  margin-top: 29px;
}
hr{
  border-top: 1px solid #e3e3e3;
}

.hr-title {
  margin-top: 10px;
}

.section-title {
  h1{
    font-weight: bold;
    font-size: 24px;
    margin: 10px 0 0 0;
  }
  .red-square {
    width: 30px;
    height: 10px;
    background: #f19700;
    display: block;
  }
}

.white {
 color: #fff;
}

.gray {
 color: #777;
}

.black {
  color: #333333;
  -o-transition:color .2s ease-out;
  -ms-transition:color .2s ease-out;
  -moz-transition:color .2s ease-out;
  -webkit-transition:color .2s ease-out;
  transition:color .2s ease-out;
}

a.white:hover, .white:focus {
    color: #f19700 !important;
    text-decoration: none !important;
    -o-transition:color .2s ease-out;
    -ms-transition:color .2s ease-out;
    -moz-transition:color .2s ease-out;
    -webkit-transition:color .2s ease-out;
    transition:color .2s ease-out;
}

a.black:hover, .black:focus {
    color: #f19700 !important;
    text-decoration: none !important;
    -o-transition:color .2s ease-out;
    -ms-transition:color .2s ease-out;
    -moz-transition:color .2s ease-out;
    -webkit-transition:color .2s ease-out;
    transition:color .2s ease-out;
}

a.gray:hover, .gray:focus {
    color: #f19700 !important;
    text-decoration: none !important;
    -o-transition:color .2s ease-out;
    -ms-transition:color .2s ease-out;
    -moz-transition:color .2s ease-out;
    -webkit-transition:color .2s ease-out;
    transition:color .2s ease-out;
}

.georgia {
    font-family: Georgia, Serif;
}

.noticia-destaque .noticia-content .categoria {
    color: #fff !important;
}

.hoverzoom {
    position: relative;
    overflow: hidden;
    .noticia-capa {
        position: relative;
        overflow: hidden;
        img {
          -webkit-transition: all .8s cubic-bezier(.190, 1.000, .220, 1.000);
          -moz-transition: all .8s cubic-bezier(.190, 1.000, .220, 1.000);
          -ms-transition: all .8s cubic-bezier(.190, 1.000, .220, 1.000);
          -o-transition: all .8s cubic-bezier(.190, 1.000, .220, 1.000);
          transition: all .8s cubic-bezier(.190, 1.000, .220, 1.000);
       }
    }
}

.hoverzoom:hover {
  .noticia-capa {
    img {
      -webkit-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -ms-transform: scale(1.5);
      -o-transform: scale(1.5);
      transform: scale(1.5);
    }
  }
}

.navbar {
  transition: height 0.2s;
}

.nav > li > a {
    padding: 10px;
}

.navbar-default {
    background-color: #fff;
    border-bottom: 2px solid #d9d9d9;
    height: 93px;
}

.navbar-default .navbar-nav > li > a {
    color: #545454;
}

.navbar-nav {
    padding-top: 25px;
    color: #545454;
    font-weight: bold;
    height: 91px;
}

.navbar-nav > li {
    height: 100%;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color: #f19700;
    background-color: transparent;
}

.navbar-default .navbar-nav > .open > a, {
    background-color: transparent !important;
}

.social-icons {
  list-style: none;
  position: absolute;
  right: 135px;
  top: 28px;
    li {
        display: inline;
        padding-right: 20px;
        cursor: pointer;
    }
    li:last-child {
        padding-right: 0;
    }
}

.navbar-nav > li > .dropdown-menu {
    margin-top: 0;
    background-color: #f19700;
    padding: 23px 0;
}

.dropdown-menu > li > a {
    color: #fff;
    letter-spacing: 0;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    text-decoration: none;
    color: #fff;
    background-color: transparent;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > .dropdown-menu > .active > a:focus {
    background-color: transparent;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus{
    background-color: transparent;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.search-box {
    width: 387px;
    height: 59px;
    border: 8px solid #878787;
    display: none;
    form {
        input {
            width: 100%;
            height: 43px;
            border-width:0px;
            border:none;
            padding-left: 13px;
            outline: none;
        }
        ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color: #d4d4d4;
        }
        :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
            color: #d4d4d4;
            opacity:  1;
        }
        ::-moz-placeholder { /* Mozilla Firefox 19+ */
            color: #d4d4d4;
            opacity:  1;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #d4d4d4;
        }
        ::-ms-input-placeholder { /* Microsoft Edge */
            color: #d4d4d4;
        }
    }
    .form-control-feedback {
        position: absolute;
        top: 5px;
        right: 0;
        z-index: 2;
        display: block;
        width: 40px;
        height: 40px;
        line-height: 34px;
        text-align: center;
        pointer-events: none;
        color: #cfcfcf;
        font-size: 23px;
    }
}

.resized {
  height: 60px;

  .navbar-brand {
    img {
      width: 220px;
      height: 31px;
    }
  }
  .navbar-nav {
    padding-top: 12px;
    color: #545454;
    font-weight: bold;
    height: 59px;
  }
  .social-icons {
      top: 15px;
  }
}

.patrocinio-fixo {
  position: fixed;
  right: 203px;
  top: 425px;
}
