footer {

    .footer-content {
      padding: 50px 90px 40px 90px;
      background-color: #f1f1f1;
      border-top: 2px solid #d9d9d9;
      a {
        color: #585858;
      }
      .social-icons-footer {
          display: inline-block;
          margin: 0 auto;
          padding-top: 70px;
          width: 120px;
          height: 220px;
          border-right: 1px solid;
          float: none;
          vertical-align: middle;
          h4 {
            font-weight: bold;
          }
          ul {
            padding: 0;
            list-style: none;
            li {
              display: inline-block;
            }
          }
      }

      .about-us {
          display: inline-block;
          padding: 0 50px;
          height: 220px;
          border-right: 1px solid;
          float: none;
          vertical-align: middle;
          h4 {
            font-weight: normal;
            margin: 15px 0 20px 0;
          }
          ul {
            padding: 0;
            list-style: none;
            color: #585858;
            li {
              padding-bottom: 10px;
            }
          }
      }

      .map {
        display: inline-block;
        padding: 0 50px;
        height: 220px;
        border-right: 1px solid;
        float: none;
        vertical-align: middle;
        strong {
          color: #545454;
        }
        h4 {
          font-weight: normal;
          margin: 15px 0 20px 0;
        }
        ul {
          padding: 0;
          list-style: none;
          color: #7b7b7b;
          width: 300px;
          -webkit-columns: 2;
          -moz-columns: 2;
          li {
            padding-bottom: 4px;
          }
        }
        .map-nested {
          list-style:initial;
          columns: 1;
          padding-top: 10px;
          li {
            padding-left: 10px;
            margin-left: 16px;
          }
        }
      }
    }
    .footer-rights {
      padding: 25px 90px 25px 90px;
      p {
        display: inline-block;
        color: #a6a6a6;
        padding-left: 7%;
      }
    }
}
