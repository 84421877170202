.colunistas-sessao {
  .feature {
    display: flex;
    margin-bottom: 40px;
    border: 1px solid #e2e2e2;
    padding: 0;

    .feature-text {
      padding-left: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      .titulo {
        font-weight: bold;
        margin-top: 0;
        font-size: 16px;
        font-family: Georgia, Serif;
      }
      .subtitulo {
        font-size: 12px;
        color: #767676;
      }
    }
  }

}
.colunista-header {
  margin-bottom: 20px;
  .titulo {
    font-size: 48px !important;
    margin-top: -10px !important;
  }
  .subtitulo {
    font-size: 24px !important;
  }
  .contato {
    display: block;
    font-size: 18px;
    color: #767676;
  }
}
