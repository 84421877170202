
@media (max-width: 1199px){
  .ministerios img {
    width: 100%;
    height: auto;
  }
  .ministerios .col-md-3 {
    width: unset !important;
    margin-right: unset !important;
  }
  .artigos-colunistas .overlay {
      width: 94% !important;
  }
  .artigos-colunistas .col-md-4 {
    padding-left: 0px !important;
  }
  .artigos-colunistas .col-md-4 {
    padding-left: 0 !important;
    overflow: hidden;
  }
  .artigos-colunistas .col-md-4 img {
    width: 100%;
    height: auto;
  }
  .topo .overlay {
    width: 94.3% !important;
  }
  .topo .noticia-destaque .noticia-content {
    bottom: 60px;
  }
  .topo .noticia-destaque .noticia-capa img {
    width: 100%;
    height: auto;
  }
  .topo .noticia-destaque-secundaria img {
    width: 100%;
    height: auto;
  }
  .topo .noticia-destaque-secundaria h5 {
    margin: 15px 0 5px 0 !important;
  }
  .topo .noticia-middle {
    width: 25% !important;
    margin-left: unset !important;
  }
  .topo .noticia-middle hr {
    margin: 10px 0 !important;
  }
  .topo .noticia-lateral {
    margin-top: 25px;
    padding-right: 15px;
  }
  .topo .noticia-lateral img {
    width: 100%;
    height: auto;
  }
  .topo .noticia-lateral hr {
    width: 100% !important;
  }
  .noticias .noticia-assets img {
    width: 100%;
    height: auto;
  }
  .noticias h4 {
    width: 100% !important;
  }
  .noticias .noticia-destaque {
    margin-top: 30px;
  }
  .noticias .noticia-destaque img {
    width: 100%;
    height: auto;
  }
  .musica-politica .feature {
    display: block !important;
  }
  .musica-politica .feature .feature-img img {
      width: 100% !important;
      height: auto;
      max-width: unset !important;
  }
  .musica-politica .feature .feature-text {
    padding-left: 0 !important;
  }
  .videos .col-md-4 {
    padding-top: 20px;
  }
  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
      width: 200px;
      margin-top: 0;
      margin-top: 12px;
  }
  .navbar-header {
      margin-top: 17px;
  }
  .artigos-colunistas .border {
    width: 100% !important;
  }
  footer .footer-content .map {
    display: none !important;
  }
  .social-icons {
    margin-left: 85px !important;
  }
  .resized .social-icons {
      margin-left: 85px !important;
  }
  .nav > li > a {
    padding: 5px !important;
  }
  .resized .navbar-brand img {
    width: 100% !important;
    height: auto !important;
  }
  .navbar-default {
    height: 75px !important;
  }
  .navbar-header {
    margin-top: 0;
  }
  .resized .navbar-nav {
    padding-top: 25px !important;
  }
}

@media (max-width: 999px){
  .nav > li > a {
      padding: 7px !important;
  }
  .navbar-brand {
    margin-top: 0 !important;
  }
  .resized .navbar-nav {
    padding-top: 12px !important;
  }
  .navbar-nav {
    padding-top: 10px !important;
    height: 45px !important;
  }
  .social-icons li {
    padding-right: 10px !important;
  }
  .resized .navbar-brand img {
    width: 200px !important;
    height: auto !important;
  }
  .navbar-default {
    height: 83px !important;
  }
  .navbar-header {
    margin-top: 0;
  }
  .social-icons {
    display: block !important;
    text-align: right;
  }
  .social-icons {
    right: 20px !important;
    top: 43px !important;
  }
  .search-box {
    margin-top: 33px;
  }
  .topo .noticia-middle {
    margin-top: 25px;
    width: 50% !important;
    margin-left: unset !important;
  }
  .topo .noticia-middle hr {
    margin: 45px 0 !important;
  }
  .topo .overlay {
    width: 96.3% !important;
  }
  .topo .noticia-destaque-secundaria h5 {
    margin: 30px 0 16px 0  !important;
  }
  .artigos-colunistas .overlay {
    width: 93% !important;
  }
  .noticia-middle {
    padding: 0 15px !important;
  }
  .ministerios h4 {
      width: 99.6% !important;
  }
  .ministerios .col-md-3 {
      width: 50% !important;
  }
  .artigos-colunistas .col-md-4 {
    padding-left: 23px !important;
  }
  footer .footer-content .map {
    display: none !important;
  }
}


@media (max-width: 400px){
  body {
    padding-top: 70px !important;
  }
  .container {
      padding-left: 15px !important;
      padding-right: 15px !important;
  }
  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-top: 16px !important;
  }
  .navbar-default .navbar-collapse, .navbar-default .navbar-form {
    margin-top: 15px !important;
  }
  .navbar-nav {
    padding: 0 20px !important;
  }
  .hide {
    display: none;
  }

  .nopadding-right {
    padding-right: 15px !important;
  }
  .topo {
    .overlay {
      position: absolute;
      width: 92% !important;
    }
    .noticia-middle {
      margin-left: 0px !important;
      width: 100% !important;
      .col-md-12 {
        padding: 0 15px !important;
      }
    }
    .noticia-destaque-secundaria {
      padding-left: 15px !important;
      padding-top: 20px;
      padding-bottom: 20px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .noticia-middle {
      margin-left: 0;
      padding-left: 15px;
      padding-top: 21px;
    }
  }
  .noticia-destaque-2 {
    img {
      width: 100%;
      height: auto;
    }
  }
  .noticia-capa img {
    width: 100%;
    height: auto;
  }

  footer {
    .footer-content {
        padding: 0 25px !important;
        height: 175px;
        .social-icons-footer {
          padding-top: 45px !important;
          border-right: 0 solid !important;
        }
        .about-us {
          padding: 0 !important;
          border-right: 0 solid !important;
        }
    }
    .map {
      display: none !important;
    }

    .footer-rights {
        img {
          width: 220px;
          height: 31px
        }
        p {
          display: block;
          padding-left: 0%;
          padding-top: 15px;
          text-align: center;
        }
    }
  }
  .topo .noticia-destaque {
      height: 240px;
  }
  .topo .noticia-middle .feature .feature-text {
    font-size: 16px !important;
    letter-spacing: -1px;
    padding-left: 10px;
  }
  .topo .noticia-middle p {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -1px;
  }
  .topo .noticia-lateral-secundaria img {
      width: 100%;
      height: auto;
  }
  .topo .noticia-lateral hr {
    width: 100%;
  }
  .topo .noticia-lateral {
    padding-top: 20px;
  }
  .topo .noticia-lateral img {
      width: 100%;
      height: auto;
  }
  .noticias img {
      width: 100%;
      height: auto;
  }
  .noticias .noticia-assets .categoria span {
    font-size: 16px;
  }
  .noticias .noticia-destaque .noticia-content .h2 {
    font-size: 21px;
  }
  .noticias .overlay {
    width: 92% !important;
  }
  .noticias .feature .feature-text {
    width: 75%;
  }
  .noticias .feature span {
    padding-bottom: 6px;
  }
  .ministerios .col-md-3 {
    width: 100% !important;
    margin-right: 0 !important;
    padding-bottom: 20px;
  }
  .ministerios .noticia-assets img {
      width: 100%;
      height: auto;
  }
  .ministerios h4 {
    width: 99.5% !important;
    height: 90px !important;
  }
  .artigos-colunistas .col-md-4 {
    padding-left: 15px !important;
    padding-top: 15px;
  }
  .artigos-colunistas .col-md-4 img {
      width: 100%;
      height: auto;
  }
  .artigos-colunistas .overlay {
    width: 92% !important;
  }

  .artigos-colunistas .colunistas {
    padding-top: 20px;
  }
  .artigos-colunistas .border {
    width: 100% !important;
  }
  .musica-politica .feature {
    display: block !important;
  }
  .musica-politica .feature .feature-img img {
      max-width:unset !important;
  }
  .musica-politica .feature .feature-img img {
    width: 100%;
    height: auto;
  }
  .musica-politica .feature .feature-text {
    padding-left:0 !important;
    padding-top: 10px;
  }
  .videos .col-md-4 {
    margin-bottom: 20px;
  }
  .h2 {
    font-size: 21px !important;
    letter-spacing: -1px;
  }
  .h3 {
      font-size: 21px !important;
      letter-spacing: -1px;
  }
  .materia .container {
      width: 100% !important;
      padding: 0 15px !important;
  }
  .materia .manchete {
    font-size: 30px !important;
  }
  .veja-mais .noticia-assets img {
    width: 100%;
    height: auto;
  }
  .veja-mais h4 {
    width: 100% !important;
    font-size: 16px;
  }
  .navbar-default .navbar-collapse, .navbar-default .navbar-form {
    background: #f19700;
    margin-top: 24px;
  }
  .navbar-default .navbar-nav > li > a {
    color: #fff !important;
  }

  .navbar-nav {
    padding-top: unset !important;
    height: unset !important;
  }
  .navbar-toggle {
    margin-top: 25px;
  }
  .navbar-nav {
    font-weight: normal !important;
  }
  .social-icons {
    text-align: center;
    padding-left: 0;
    width: 100%;
    display: none !important;
  }
  .resized .navbar-toggle {
    margin-top: 12px;
  }

  .dropdown-resized {
    margin-top: 4px !important;
  }
  .navbar-nav .open .dropdown-menu {
    padding: 0
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #fff;
  }
  .nopadding-left {
    padding-left: 15px !important;
  }
  .width-100 {
    width: 100% !important;
  }
  .secao-1 .noticia-lateral .col-md-12 {
    width: 100% !important;
  }

  .familia-missoes-colunistas .feature .feature-text {
    padding-left: 15px !important;
  }
  .familia-missoes-colunistas .feature .feature-text .subtitulo {
    font-size: 15px !important;
    letter-spacing: -1px !important;
  }
  .familia-missoes-colunistas .feature .feature-text .subtitulo {
    font-size: 15px !important;
    letter-spacing: -1px !important;
  }
  .ultimas-noticias .feature .feature-text {
    padding-left: 0 !important;
  }
  .ultimas-noticias .feature .feature-year {
    display: none !important;
  }
  .ultimas-noticias .feature .feature-text .subtitulo {
    display: none !important;
  }
  .logo-ministerio {
    padding-bottom: 25px !important;
  }
  .logo-ministerio img {
    width: 100%;
    height: auto;
  }
  .secao-6 .noticia-lateral .noticia-pequena h5 {
    display: none;
  }
  .secao-6 .noticia-lateral .feature .feature-text {
    width: 75%;
  }
  .secao-6 .noticia-lateral .noticia-pequena {
    margin: 25px 0;
  }
  .materia .autor-hora {
    display: block !important;
    margin-top: 15%;
    margin-bottom: 10px !important;
  }
  .materia .addtoany_share_save_container {
    display: block !important;
    margin: 0 0 20px 0 !important;
    width: 100% !important;
  }
  .ultimas-noticias .merchan {
    margin-right: unset !important;
    width: 100%;
    margin-bottom: 20px;
  }
  .ultimas-noticias .merchan img {
    width: 100%;
    height: auto;
  }
.ultimas-noticias .feature .feature-text .titulo {
    font-size: 16px !important;
}
.merchan-fixed {
  position: relative;
}
}
