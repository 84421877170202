.materia {
  padding-bottom: 100px;

    .container {
      width: 1020px;
    }
    .manchete {
      font-size: 48px;
      font-weight: bold;
    }
    .sub-titulo {
      font-size: 20px;
      color: #666666;
      margin: 30px 0;
    }
    .capa-texto {
      img {
        width: 100% !important;
      }
    }
    .autor-hora {
      display: inline-block;
      margin-bottom: 30px;
      color: #777;
    }
    .addtoany_share_save_container {
      position: absolute;
      top: 0;
      right: 0;
      padding-right: 15px;
    }
    .addtoany_share_save_container:last-child {
      position: relative;
      text-align: right;
    }
    .ministerios {
      .titulo {
        font-size: 14px;
      }
    }
    .comentarios, .colunista {
      padding-top: 40px;
    }
    .texto-materia {
      overflow: hidden;
    }
    .attachment-full {
      width: 100% !important;
      height: auto;
      padding-bottom: 20px;
    }
    .colunistas {
      .feature {
        border: 1px solid #e2e2e2;
        margin: 10px 0;
        margin-top: 0;
        display: flex;
        .feature-img {
          img {
            max-width: 150px;
          }
        }
        .feature-text {
          padding-left: 10px;
        }
        .feature-img-pequena {
          img {
            width: 80px;
            height: auto;
          }
        }
        .titulo {
          margin-top: 0;
          font-size: 16px;
          font-family: Georgia, Serif;
        }
        .subtitulo {
          font-size: 12px;
          color: #767676;
        }
      }
    }
    .mais-lidas {
      .feature {
        margin: 10px 0;
        display: flex;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -1px;
        .feature-img {
          img {
            max-width: 150px;
          }
        }
        .feature-text {
            padding: 10px 10px 10px;
        }
        .feature-img-pequena {
          img {
            width: 80px;
            height: auto;
          }
        }
        .titulo {
          margin-top: 0;
          font-size: 16px;
          font-family: Georgia, Serif;
        }
        .subtitulo {
          font-size: 12px;
          color: #767676;
        }
      }
    }
}
