/*
Theme Name: ShofarPost
Theme URI: http://www.shofarpost.com.br/
Description: ShofarPost Portal Theme
Author: Guilherme Matos
Author URI: https://www.guimatos.com/
*/


@import 'bootstrap-sass/assets/stylesheets/bootstrap';
@import 'media-queries';
@import 'normalize';
@import 'home';
@import 'secao-1';
@import 'secao-6';
@import 'materia';
@import 'colunistas';
@import 'ministerio';
@import 'vejamais';
@import 'footer';
