
.veja-mais {
  margin-top: 40px;

  .noticia-assets {
    position: relative;
    border: 1px #e2e2e2 solid;

    .categoria {
      position: absolute;
      bottom: 0;
      margin-left: 15px;
      span {
        display: block;
        color: #fff;
        background: #f19700;
        width: 100%;
        padding: 5px;
      }
    }
  }
  h4 {
    padding: 10px 10px 0 10px;
    margin-top: 0;
    border: 1px #e2e2e2 solid;
    height: 66px;
    width: 207px;
    margin-left: 1px;
    font-size: 14px;
  }
}
