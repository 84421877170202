.secao-1 {
  .noticia-lateral {
    .col-md-12 {
      width: 330px;
    }
    .feature {
      display: flex;
      margin-top: 28px;
      .feature-img {
        img {
          max-width: 120px;
        }
      }
      .feature-text {
        margin-top: -5px;
        font-size: 16px;
        padding-left: 10px;
      }
    }
  }
}

.mundo-cristao {
  margin-top: 40px;
}

.familia-missoes-colunistas {
  margin-top: 40px;
  .feature {
    display: flex;
    margin-bottom: 10px;
    .feature-img {
      img {
        max-width: 240px;
      }
    }
    .feature-text {
      padding-left: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      .titulo {
        font-size: 14px;
        font-weight: bold;
        margin-top: -15px;
      }
      .subtitulo {
        font-size: 18px;
      }
    }
  }
  .colunistas {
    .feature {
      border: 1px solid #e2e2e2;
      padding: 0;
      .feature-img-pequena {
        img {
          max-width: 76px;
          height: auto;
        }
      }
      .titulo {
        margin-top: 0;
        font-size: 16px;
        font-family: Georgia, Serif;
      }
      .subtitulo {
        font-size: 12px;
        color: #767676;
      }
    }
  }
}

.ultimas-noticias {
  margin-top: 50px;

  .merchan {
      margin-right: 12px;
  }
  .feature {
    display: flex;
    margin-bottom: 10px;
    .feature-year {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #999999;
      width: 35%;
    }
    .feature-img {
      img {
        max-width: 240px;
      }
    }
    .feature-text {
      padding-left: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      .titulo {
        font-size: 21px;
        font-weight: bold;
      }
      .subtitulo {
        font-size: 14px;
        color: #333333;
        margin-right: 80px;
      }
    }
  }
  .colunistas {
    .feature {
      border: 1px solid #e2e2e2;
      padding: 0;
      .titulo {
        margin-top: 0;
        font-size: 16px;
      }
      .subtitulo {
        font-size: 12px;
        color: #767676;
      }
    }
  }
}
