.secao-6 {
  .noticia-lateral {

    .noticia-pequena {
      margin-top: 35px;
      h5 {
        padding-left: 129px;
      }
    }
    .feature {
      display: flex;
      .feature-img {
        img {
          max-width: 120px;
        }
      }
      .feature-text {
        margin-top: -5px;
        font-size: 16px;
        padding-left: 10px;
        letter-spacing: -1px;
        display: flex;
        align-items: center;
      }
    }
    .colunistas {
      .feature {
        border: 1px solid #e2e2e2;
        padding: 0;
        margin-top: 0;
        .feature-img {
          img {
              width: 150px;
              height: auto;
          }
        }
        .titulo {
          margin-top: 0;
          font-size: 16px;
          font-family: Georgia, Serif;
        }
        .subtitulo {
          font-size: 12px;
          color: #767676;
        }
      }
    }
  }
}
