.logo-ministerio {
  padding-bottom: 60px;

  img {
    margin: 0 auto;
    display: flex;
  }
}

.section-title {
  .black-square {
      width: 30px;
      height: 10px;
      background: #000;
      display: block;
  }
}

.ministerios {
  hr {
    margin-bottom: 0;
  }
  .col-md-3 {
    width: 279px;
    margin-right: 17.5px;
    padding-top: 40px;
  }
}

.topo .noticia-destaque .noticia-content .categoria-black {
    display: block;
    background: #000;
    padding: 10px 0;
    width: 100px;
    font-size: 14px;
    text-align: center;
}

.ministerios .noticia-assets .categoria-black span {
    display: block;
    color: #fff;
    background: #000 !important;
    width: 100%;
    padding: 5px;
}
