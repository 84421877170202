.topo {
  .overlay {
    position: absolute;
    width: 95%;
    height: 75%;
    top: 25%;
    z-index: 998;
    background: -webkit-linear-gradient(left, rgba(255,0,0,0), #000);
    background: -o-linear-gradient(right, rgba(255,0,0,0), rgba(255,0,0,1));
    background: -moz-linear-gradient(right, rgba(255,0,0,0), rgba(255,0,0,1));
    background: linear-gradient(rgba(0, 0, 0, 0), #000);
  }
  .noticia-destaque {
    color: #fff;
    .noticia-content {
      position: absolute;
      bottom: 10px;
      width: 95%;
      padding: 0 25px;
      z-index: 999;
      .categoria {
        bottom: 130px;
        position: absolute;
        span {
          display: block;
          background: #f19700;
          padding: 6.5px 10px;
          width: 100%;
          font-size: 14px;
          text-align: center;
          bottom: 37px;
        }
      }
    }
  }
  .noticia-destaque-secundaria {
      padding-left: 7px;
      h3 {
        letter-spacing: -1px;
        font-size: 24px;
        margin: 0;
      }
      h5 {
        margin: 21px 0 16px 0;
      }
  }
  .noticia-middle {
    padding: 0;
    width: 263px;
    margin-left: 7px;

    h5 {
      margin-top: 0;
    }
    p {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -1px;
    }

    .col-md-12 {
      padding: 0 21px;
    }

    .feature {
      display: flex;
      .feature-img {
        img {
          max-width: 120px;
        }
      }
      .feature-text {
        font-size: 12px;
        padding-left: 10px;
      }
    }
  }
  .noticia-lateral {
    h3 {
      font-weight: bold;
      font-size: 22px;
      letter-spacing: -1px;
    }
    hr {
      width: 300px;
    }
  }
  .noticia-lateral-secundaria {
    h5 {
      margin: 20px 0 10px 0;
    }
    h3 {
      margin: 0 0 5px 0;
    }
  }
}

.topo .noticia-destaque:hover {
    color: #f19700;
}

.noticias {
  margin-top: 40px;
  .overlay {
    position: absolute;
    width: 95%;
    background: #000;
    height: 75%;
    top: 25%;
    z-index: 998;
    background: -webkit-linear-gradient(left, rgba(255,0,0,0), #000);
    background: -o-linear-gradient(right, rgba(255,0,0,0), rgba(255,0,0,1));
    background: -moz-linear-gradient(right, rgba(255,0,0,0), rgba(255,0,0,1));
    background: linear-gradient(rgba(0, 0, 0, 0), #000);
  }
  .noticia-assets {
    position: relative;
    .categoria {
      position: absolute;
      bottom: 0;
      span {
        display: block;
        background: #fff;
        width: 100%;
        padding: 3px 5px 0;
      }
    }
  }
  .subtitle {
    color: #767676;
    font-size: 16px;
  }
  .noticia-destaque {
    color: #fff;
    overflow: hidden;
    .noticia-content {
      position: absolute;
      bottom: 0;
      width: 95%;
      padding: 0 25px;
      z-index: 999;
      .categoria {
        display: block;
        background: #f19700;
        padding: 10px 0;
        width: 100px;
        font-size: 14px;
        text-align: center;
      }
      .titulo {
        font-weight: bold;
      }
      .h2 {
        font-size: 30px;
      }
      .h3 {
        font-size: 24px;
      }
    }
  }
  .feature {
    display: flex;
    span {
      display: block;
      padding-bottom: 10px;
    }
    .feature-img {
      img {
        max-width: 120px;
      }
    }
    .feature-text {
      font-size: 12px;
      padding-left: 10px;
    }
  }
  h4 {
    width: 279px
  }
}

.ministerios {
  margin-top: 40px;

  .noticia-assets {
    position: relative;
    border: 1px #e2e2e2 solid;

    .categoria {
      position: absolute;
      bottom: 0;
      margin-left: 15px;
      span {
        display: block;
        color: #fff;
        background: #f19700;
        width: 100%;
        padding: 5px;
      }
    }
  }
  h4 {
    padding: 10px;
    margin: -2px 0 0 1px;
    border: 1px #e2e2e2 solid;
    width: 279px;
    height: 90px;
  }
}

.artigos-colunistas {
  margin-top: 40px;
  .col-md-4 {
    padding-left: 4px;
  }
  .bottom {
    margin-top: 36px;
  }

  .overlay {
    position: absolute;
    width: 95%;
    background: #000;
    height: 75%;
    top: 25%;
    z-index: 998;
    background: -webkit-linear-gradient(left, rgba(255,0,0,0), #000);
    background: -o-linear-gradient(right, rgba(255,0,0,0), rgba(255,0,0,1));
    background: -moz-linear-gradient(right, rgba(255,0,0,0), rgba(255,0,0,1));
    background: linear-gradient(rgba(0, 0, 0, 0), #000);
  }
  .noticia-content {
    width: 95%;
    position: absolute;
    padding: 0 30px 30px 30px;
    bottom: 0;
    color: #fff;
    z-index: 999;
    .categoria {
      display: inline-block;
      background: #f19700;
      padding: 10px;
      // width: 100px;
      font-size: 14px;
      text-align: center;
    }
    .autor {
      display: inline-block;
      background: #838383;
      padding: 10px;
      margin-left: -4px;
      // width: 100px;
      font-size: 14px;
      text-align: center;
    }
  }
  .feature {
    display: flex;
    border: 1px solid #e2e2e2;
    margin-bottom: 22px;
    .feature-img {
      img {
        max-width: 120px;
      }
    }
    .feature-text {
      padding-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 14px;
      }
      .titulo {
        font-size: 16px;
        font-weight: bold;
      }
      .subtitulo {
        color: #8d8d8d;
        font-size: 12px;
      }
    }
    .feature-img-pequena {
      img {
        width: 80px;
        height: auto;
      }
    }
  }
  .border {
    border: 1px solid #e2e2e2;
    width: 281px;
    height: 226px;
  }
  .colunistas {
    .feature {
      margin-bottom: 8px;
    }
  }
}

.musica-politica {
  margin-top: 40px;
  .feature {
    display: flex;
    margin-bottom: 10px;

    padding: 30px 0;
    .feature-img {
      img {
        max-width: 240px;
      }
    }
    .feature-text {
      padding-left: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      .titulo {
        font-size: 14px;
        font-weight: bold;
      }
      .subtitulo {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -1px;
      }
    }
  }
  .separador-noticia {
    border-top: 1px solid #ebebeb;
  }
}

.videos {
  margin-top: 40px;
  margin-bottom: 50px;

  .overlay {
    position: absolute;
    width: 92.5%;
    height: 75%;
    top: 25%;
    z-index: 998;
    background: -webkit-linear-gradient(left, rgba(255,0,0,0), #000);
    background: -o-linear-gradient(right, rgba(255,0,0,0), rgba(255,0,0,1));
    background: -moz-linear-gradient(right, rgba(255,0,0,0), rgba(255,0,0,1));
    background: linear-gradient(rgba(0, 0, 0, 0), #000);
  }
  .noticia-content {
    width: 92%;
    position: absolute;
    padding: 0 30px 10px 30px;
    bottom: 0;
    color: #fff;
    z-index: 999;
    .categoria {
      display: inline-block;
      background: #f19700;
      padding: 10px;
      // width: 100px;
      font-size: 14px;
      text-align: center;
    }
    .autor {
      display: inline-block;
      background: #838383;
      padding: 10px;
      margin-left: -4px;
      // width: 100px;
      font-size: 14px;
      text-align: center;
    }
  }
  .col-md-4 {
      height: 226px;
  }
}
